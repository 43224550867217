import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout';
import 'react-responsive-modal/styles.css';
import Seo from '../components/seo';
import OtherGlossaryLinks from '../components/OtherGlossaryLinks';

export const query = graphql`
  query($id: ID!) {
    wpcontent {
      glossary(id: $id) {
        title
        uri
        content
        excerpt
        seo {
          title
          metaDesc
          canonical
        }
      }
      glossaries(first: 12 where: {notIn: [$id]}) {
            nodes {
                title
                uri
            }
        }
    }
  }
`

const GlossaryTemplate = ({data}) => {

    const glossary = data.wpcontent.glossary
    const metaDesc = data.wpcontent.glossary.seo.metaDesc
    const pageTitle = data.wpcontent.glossary.seo.title
    const glossaries = data.wpcontent.glossaries.nodes
    let uri = data.wpcontent.glossary?.uri
    
    let baseUrl = "https://www.vantagecircle.com";

    let pageUri = baseUrl + uri
  
    return (
      <Layout className="">
         <Seo title={pageTitle}
                description={metaDesc}
                link={[
                    {
                        rel: `canonical`,
                        href: pageUri,
                    },
                ]}
            />
        <div className='max-w-7xl mx-auto mt-5 px-5 xl:px-0'>

          <div className='flex'>
            <a href='https://www.vantagecircle.com/glossaries/' className='text-orange text-sm'>
              Glossary
            </a>
            <p className='text-sm'>&nbsp;&gt;&gt; {glossary.title}</p>
          </div>

          <div className='xl:flex pb-10 xl:pb-10'>
            <div className='w-full xl:w-8/12'>
              <h1 className='homepage-section-heading pt-4'>{glossary.title}</h1>
              <div className='' dangerouslySetInnerHTML={{ __html: glossary.content }} />
            </div>
            <div className='w-full xl:w-4/12 flex items-start justify-center xl:justify-end xl:pr-5 xl:pl-8 pt-10 xl:pt-20'>
              <a href="https://www.vantagecircle.com/request-demo/">
                <picture>
                  <source class='' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/04/Glossary-side-banner.png" media= "(min-width: 640px)" width="400" type="image/webp" alt="glossary-banner" />
                  <img class='' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1631098362/gatsbycms/uploads/2024/04/Glossary-side-banner.png" alt="glossary-banner" width="250" />
                </picture>
              </a>
            </div>
          </div>
          <h2 className='homepage-div-heading py-10'>Related glossaries</h2>
          <ul className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 pb-10 purplebullets'>
            {
              glossaries.map(item => {
                return(
                  <li>
                    <a href={item.uri} className='homepage-mini-heading text-indigo-100 hover:text-orange'>{item.title}</a>
                  </li>
                )
              })
            }
          </ul>
        </div>

      </Layout>
    )
}

export default GlossaryTemplate